// Add your JS customizations here

function raitingStart() {
    

    console.log('raitingStart() DOWNLOADED');

    const reviewRaitingElems = document.querySelectorAll('.review__raiting');
    reviewRaitingElems.forEach((el) => {
        console.log('raitingStart FOR EACH WORKing...')
        // 92px == 100% == 5
        // 92 * 0,2 * 5
        console.log(el);   
        let result = Number(el.innerHTML);
        console.log(result);  
        console.log(el.style.width);  
        el.style.width = `${result * 0.2 * 92}px`;  
        console.log(el.style.width);   
        console.log('FOR EACH ENDing...') 
    })  

    console.log('raitingStart() STOPed'); 

}   
document.addEventListener('DOMContentLoaded', raitingStart);  